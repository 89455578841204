import React from "react";
import { useDispatch } from "react-redux";
import { setLogout } from "../../store/slices/auth";
import { useNavigate } from "react-router-dom";
import { BiLogOut, BiTv } from "react-icons/bi";
const { REACT_APP_SITE_URL } = process.env;

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logMeOut = () => {
    dispatch(setLogout());
    navigate("/login");
  };

  const openCustomerDisplay = (url) => {
    const newWindow = window.open(`${REACT_APP_SITE_URL}/${url}`, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="absolute right-0 -mt-2 mr-10 py-2 w-48 bg-white rounded-md shadow-xl z-20">
      <div
        onClick={() => openCustomerDisplay("customer-display")}
        className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer"
      >
        <BiTv />
        <p className="text-sm capitalize">Customer Screen</p>
      </div>
      <div
        onClick={() => logMeOut()}
        className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-blue-500 hover:text-white cursor-pointer"
      >
        <BiLogOut />
        <p className="text-sm capitalize">Sign Out</p>
      </div>
    </div>
  );
};

export default Logout;
