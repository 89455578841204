import { Login } from "../../pages/public";
import {
  Dashboard,
  Order,
  Pay,
  CreditNote,
  HoldOrders,
  Sales,
  Purchase,
  Expense,
  CashInHand,
  CustomerDisplay,
} from "../../pages/private";

const publicRoutes = [
  {
    path: "",
    component: <Login />,
    exact: true,
    key: "login",
  },
  {
    path: "/login",
    component: <Login />,
    exact: false,
    key: "login",
  },
];

const privateRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: false,
    key: "dashboard",
    withNav: true,
  },
  {
    path: "/pay",
    component: <Pay />,
    exact: false,
    key: "pay",
    withNav: true,
  },
  {
    path: "/order",
    component: <Order />,
    exact: false,
    key: "pay",
    withNav: true,
  },
  {
    path: "/credit-note",
    component: <CreditNote />,
    exact: false,
    key: "pay",
    withNav: true,
  },
  {
    path: "/payment/sales",
    component: <Sales />,
    exact: false,
    key: "sales",
    withNav: true,
  },
  {
    path: "/payment/purchase",
    component: <Purchase />,
    exact: false,
    key: "purchase",
    withNav: true,
  },
  {
    path: "/payment/expense",
    component: <Expense />,
    exact: false,
    key: "expense",
    withNav: true,
  },
  {
    path: "/payment/cash-in-hand",
    component: <CashInHand />,
    exact: false,
    key: "cash-in-hand",
    withNav: true,
  },
  {
    path: "/hold",
    component: <HoldOrders />,
    exact: false,
    key: "pay",
    withNav: true,
  },
  {
    path: "/customer-display",
    component: <CustomerDisplay />,
    exact: false,
    key: "pay",
    withNav: false,
  },
];

export { publicRoutes, privateRoutes };
