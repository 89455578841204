import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

export function useLoadingWithRefresh() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        setLoading(false);
      } catch (err) {
        console.log("Err", err);
        setLoading(false);
      }
    })();
  }, [dispatch]);

  return { loading };
}
