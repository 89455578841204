import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import {
  BiArchiveIn,
  BiChevronDown,
  BiTrash,
  BiMinus,
  BiPauseCircle,
  BiPlus,
  BiTrashAlt,
  BiPlusCircle,
  BiMinusCircle,
} from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  Label,
  TextPassword,
  TextInput,
  Logout,
} from "./../../components";

import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  minusQuantity,
  plusQuantity,
  removeItemFromCart,
  emptyMyCart,
} from "../../store/slices/cart";
import * as api from "../../http";
import { toast } from "react-hot-toast";
import * as moment from "moment";

const BillingContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.cart.cartItems);

  const PasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
  });

  const [dropDownOpened, setDropDownOpened] = useState(false);
  const openDropdown = () => setDropDownOpened(!dropDownOpened);

  const [cartItems, setCartItems] = useState([]);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isChargesModalOpen, setIsChargesModalOpen] = useState(false);
  const [isUserAuthenticated, setUserAuthenticated] = useState(true);

  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [discountInitialValue, setDiscountInitialValue] = useState({
    discount_type: "",
    discount: "",
  });

  const [additionalCharges, setAdditionalCharges] = useState(0);
  const [additionalChargesInitialValues, setAdditionalChargesInitialValues] =
    useState({
      additional_charges: [
        {
          charge_name: "",
          amount: "",
          tax: "",
          total_amount: "",
        },
      ],
    });

  useEffect(() => {
    setCartItems(items);

    if (items.length > 0) {
      localStorage.setItem("cart-items", JSON.stringify(items));
      if (localStorage.getItem("cart-discount")) {
        let d = JSON.parse(localStorage.getItem("cart-discount"));
        setDiscountInitialValue(d);
        setDiscountType(d.discount_type);
        setDiscount(d.discount);
      }

      if (localStorage.getItem("cart-additional-charges")) {
        let d = JSON.parse(localStorage.getItem("cart-additional-charges"));
        setAdditionalChargesInitialValues(d);
        let ac = 0;
        d.additional_charges.forEach((i) => {
          ac = ac + i.total_amount;
        });
        setAdditionalCharges(ac);
      }
    } else {
      localStorage.removeItem("cart-discount");
      localStorage.removeItem("cart-additional-charges");
      localStorage.removeItem("cart-items");
    }
  }, [items]);

  const removeItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  const plusQnt = (id) => {
    dispatch(plusQuantity(id));
  };

  const minusQnt = (id) => {
    dispatch(minusQuantity(id));
  };

  const getSubTotal = () => {
    let totalAmt = 0;
    cartItems.forEach((i) => {
      totalAmt = totalAmt + parseInt(i.cart_qnt) * i.mrp;
    });
    return totalAmt;
  };

  const getTax = () => {
    let totalTax = 0;
    cartItems.forEach((i) => {
      let amt = parseInt(i.cart_qnt) * i.mrp;
      if (i.add_vat === "yes") {
        totalTax = totalTax + (amt * 20) / 100;
      }
    });

    return totalTax;
  };

  const getTotal = () => {
    let value = getSubTotal();

    if (discountType === "amount" && value > discount) {
      value = value - parseInt(discount);
    }

    if (discountType === "percentage") {
      let da = (value * parseInt(discount)) / 100;
      value = value - da;
    }
    value = value + additionalCharges + getTax();

    localStorage.setItem("cart-total", value);

    return value;
  };

  const emptyCart = () => {
    dispatch(emptyMyCart());
    setCartItems([]);
    setAdditionalChargesInitialValues({
      additional_charges: [
        {
          charge_name: "",
          amount: "",
          tax: "",
          total_amount: "",
        },
      ],
    });
    setAdditionalCharges(0);
    setDiscount(0);
    setDiscountType(0);
    setDiscountInitialValue({
      discount_type: "",
      discount: "",
    });
    localStorage.removeItem("cart-items");
    localStorage.removeItem("cart-total");

    navigate("/dashboard");
  };

  const holdOrder = () => {
    let holdOrders = localStorage.getItem("hold-orders");
    let invId = localStorage.getItem("last-invoice-id");

    if (holdOrders) {
      holdOrders = JSON.parse(holdOrders);
      let oi2 = parseInt(invId) + 1;
      holdOrders.push({
        invoice_no: oi2,
        date: moment().format("DD-MM-YYYY"),
        customer_name: "",
        amount: getTotal(),
        order_type: "",
        cart_items: cartItems,
        discount: discountInitialValue,
        additional_charges: additionalChargesInitialValues,
      });

      localStorage.setItem("hold-orders", JSON.stringify(holdOrders));
      localStorage.setItem("last-invoice-id", oi2);

      emptyCart();
    } else {
      let ho = [];
      ho.push({
        invoice_no: 1,
        date: moment().format("DD-MM-YYYY"),
        customer_name: "",
        amount: getTotal(),
        order_type: "",
        cart_items: cartItems,
        discount: discountInitialValue,
        additional_charges: additionalChargesInitialValues,
      });
      localStorage.setItem("hold-orders", JSON.stringify(ho));
      localStorage.setItem("last-invoice-id", 1);
      emptyCart();
    }
  };

  const openCashDrawer = () => {};

  return (
    <div className="h-screen overflow-y-auto border w-1/2">
      {/* Navbar with dropdown */}
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className="flex justify-end items-center space-x-2 py-2 cursor-pointer px-6"
            onClick={openDropdown}
          >
            <Avatar name="Kunal" round={true} size="40" className="text-xs" />
            <span>Kunal Dholiya</span>
            <button className="relative z-[-10] block rounded-md bg-white p-2 focus:outline-none">
              <BiChevronDown className="h-6 w-6 text-gray-800" />
            </button>
          </div>
          {dropDownOpened ? <Logout /> : <></>}
          <div className="flex flex-row justify-between items-center py-3 px-6">
            <h1 className="font-bold text-xl">Bills</h1>
            <div className="flex space-x-4">
              <div>
                <BiTrashAlt
                  className="h-7 w-7 text-[#3C81FC] cursor-pointer"
                  onClick={() => emptyCart()}
                />
              </div>
              {location.pathname === "/pay" && (
                <div>
                  <BiPauseCircle
                    className="h-7 w-7 text-teal-500 cursor-pointer"
                    onClick={() => holdOrder()}
                  />
                </div>
              )}
              {location.pathname === "/pay" && (
                <div>
                  <BiArchiveIn
                    className="h-7 w-7 text-orange-500 cursor-pointer"
                    onClick={() => openCashDrawer()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`px-6 h-[22.5rem] overflow-y-auto mt-5`}>
            {cartItems.map((ci, index) => (
              <div className="grid grid-cols-2 gap-4 mb-4" key={index}>
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src={ci.image}
                    alt={ci.product_name}
                    className="w-16 rounded-lg"
                  />
                  <div className="flex flex-col gap-y-1">
                    <h1 className="font-bold">{ci.product_name}</h1>
                    <p className="text-sm text-gray-600">MRP: {ci.mrp}</p>
                  </div>
                </div>
                <div className="flex justify-end items-center gap-2">
                  <div className="p-2 border rounded-full">
                    <BiPlus
                      className="h-4 w-4 text-[#3C81FC] cursor-pointer"
                      onClick={() => plusQnt(ci.id)}
                    />
                  </div>
                  <div>
                    <p>{ci.cart_qnt}</p>
                  </div>
                  <div className="p-2 border rounded-full">
                    <BiMinus
                      className="h-4 w-4 text-[#3C81FC] cursor-pointer"
                      onClick={() => minusQnt(ci.id)}
                    />
                  </div>
                  <div className="">
                    <BiTrash
                      className="h-6 w-6 text-red-500 cursor-pointer"
                      onClick={() => removeItem(ci.id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-[#DBE4F2] py-3 px-6">
          <div className="flex flex-row justify-between items-center mb-3 text-sm">
            <div>Subtotal</div>
            <div>£{getSubTotal()}</div>
          </div>
          <div className="flex flex-row justify-between items-center mb-3 text-sm">
            <div>Discount</div>
            {discount === 0 && (
              <div
                className="flex flex-row items-center space-x-2 text-[#3C81FC]"
                onClick={() => setIsDiscountModalOpen(true)}
              >
                <BiPlus /> <span> Add Discount</span>
              </div>
            )}

            {discount !== 0 && (
              <div
                className="flex flex-row items-center space-x-2 text-[#3C81FC]"
                onClick={() => setIsDiscountModalOpen(true)}
              >
                {discount} {discountType === "percentage" ? "%" : "£"}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between items-center mb-3 text-sm">
            <div>Charges</div>

            {additionalCharges === 0 && (
              <div
                className="flex flex-row items-center space-x-2 text-[#3C81FC]"
                onClick={() => setIsChargesModalOpen(true)}
              >
                <BiPlus /> <span> Add Charges</span>
              </div>
            )}

            {additionalCharges !== 0 && (
              <div
                className="flex flex-row items-center space-x-2 text-[#3C81FC]"
                onClick={() => setIsChargesModalOpen(true)}
              >
                {additionalCharges}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between items-center mb-4 text-sm">
            <div>Taxes (20%)</div>
            <div>£{getTax()}</div>
          </div>
          {location.pathname === "/dashboard" && (
            <>
              <Link to="/pay">
                <p className="bg-[#1267FF] py-3 text-white rounded-full w-full text-center">{`Pay - £${getTotal()}`}</p>
              </Link>
            </>
          )}

          {location.pathname === "/pay" && (
            <>
              <hr className=" !border-black" />
              <div className="flex flex-row justify-between items-center mt-4 mb-4 text-sm">
                <div>Payable Amount</div>
                <div>£{getTotal()}</div>
              </div>
            </>
          )}
        </div>
      </div>
      {isDiscountModalOpen && (
        <Modal setIsOpen={setIsDiscountModalOpen}>
          <div className="w-96 h-auto p-8">
            <h1 className="text-[#142243] font-bold text-xl">Add Discount</h1>
            <p className="text-[#525D68] text-sm">Please enter your password</p>

            <div className="mt-6">
              {!isUserAuthenticated && (
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validationSchema={PasswordSchema}
                  onSubmit={async (values) => {
                    values.username = JSON.parse(
                      localStorage.getItem("pos-user")
                    ).id;

                    try {
                      const response = await api.login(values);
                      if (response.status === 200 || response.status === 201) {
                        setUserAuthenticated(true);
                      } else {
                        toast.error(response.data.message, {
                          position: "top-right",
                        });
                      }
                    } catch (err) {
                      toast.error("Something went wrong! Please try again.", {
                        position: "top-right",
                      });
                    }
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      {/* First check password */}
                      <div className="mb-6">
                        <Label text="Password" isRequired={true} />
                        <TextPassword
                          placeholder="**********"
                          name="password"
                          isError={errors.password && touched.password}
                        />
                        {errors.password && touched.password ? (
                          <span className="text-xs text-red-500">
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                      <div className="">
                        <Button text="Submit" isEnabled="true" type="submit" />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {isUserAuthenticated && (
                <Formik
                  initialValues={discountInitialValue}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    setDiscount(values.discount);
                    setDiscountType(values.discount_type);
                    setDiscountInitialValue({
                      discount_type: values.discount_type,
                      discount: values.discount,
                    });
                    localStorage.setItem(
                      "cart-discount",
                      JSON.stringify(values)
                    );
                    setIsDiscountModalOpen(false);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div>
                        <p className="text-[#142243] text-sm mb-2">
                          Choose discount method
                        </p>
                        <div className="flex flex-row gap-4 items-center mb-5">
                          <div className="flex items-center">
                            <Field
                              type="radio"
                              name="discount_type"
                              value="percentage"
                              className="focus:ring-[#3C81FC] h-4 w-4 text-[#3C81FC] border-gray-300"
                            />

                            <label className="ml-3 block text-md font-medium text-[#142243]">
                              Percentage
                            </label>
                          </div>
                          <div className="flex items-center">
                            <Field
                              type="radio"
                              name="discount_type"
                              value="amount"
                              className="focus:ring-[#3C81FC] h-4 w-4 text-[#3C81FC] border-gray-300"
                            />
                            <label className="ml-3 block text-md font-medium text-[#142243]">
                              Amount
                            </label>
                          </div>
                        </div>

                        <div className="mb-6">
                          <Label text="Discount" isRequired={true} />
                          <TextInput
                            placeholder="Enter discount amount"
                            name="discount"
                          />
                        </div>

                        <div className="">
                          <Button
                            text="Add Discount"
                            isEnabled="true"
                            type="submit"
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </Modal>
      )}

      {isChargesModalOpen && (
        <Modal setIsOpen={setIsChargesModalOpen}>
          <div className="h-auto p-8 w-[56rem]">
            <h1 className="text-[#142243] font-bold text-xl">
              Add Additional charge
            </h1>

            <div className="mt-6">
              <Formik
                initialValues={additionalChargesInitialValues}
                enableReinitialize={true}
                onSubmit={(values) => {
                  setAdditionalChargesInitialValues(values);
                  let ac = 0;
                  values.additional_charges.forEach((i) => {
                    ac = ac + i.total_amount;
                  });
                  setAdditionalCharges(ac);

                  localStorage.setItem(
                    "cart-additional-charges",
                    JSON.stringify(values)
                  );

                  setIsChargesModalOpen(false);
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <FieldArray
                      name="additional_charges"
                      render={({ insert, remove, push }) => (
                        <>
                          {values.additional_charges &&
                            values.additional_charges.length > 0 &&
                            values.additional_charges.map((item, index) => (
                              <div
                                className="flex items-center space-x-4 mb-6"
                                key={index}
                              >
                                <div>
                                  <Label text="Charge Name" isRequired={true} />
                                  <TextInput
                                    placeholder="Enter Name"
                                    name={`additional_charges.${index}.charge_name`}
                                    isError={
                                      errors.additional_charges &&
                                      errors.additional_charges[index] &&
                                      errors.additional_charges[index]
                                        .charge_name &&
                                      touched.additional_charges &&
                                      touched.additional_charges[index] &&
                                      touched.additional_charges[index]
                                        .charge_name
                                    }
                                  />
                                  {errors.additional_charges &&
                                  errors.additional_charges[index] &&
                                  errors.additional_charges[index]
                                    .charge_name &&
                                  touched.additional_charges &&
                                  touched.additional_charges[index] &&
                                  touched.additional_charges[index]
                                    .charge_name ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.additional_charges[index]
                                          .charge_name
                                      }
                                    </span>
                                  ) : null}
                                </div>

                                <div>
                                  <Label text="Amount" isRequired={true} />
                                  <Field
                                    type="text"
                                    className={`${
                                      errors.additional_charges &&
                                      errors.additional_charges[index] &&
                                      errors.additional_charges[index].amount &&
                                      touched.additional_charges &&
                                      touched.additional_charges[index] &&
                                      touched.additional_charges[index].amount
                                        ? "!border-red-500"
                                        : null
                                    } border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                                    placeholder="Enter Amount"
                                    name={`additional_charges.${index}.amount`}
                                    autoComplete="off"
                                    onBlur={(e) => {
                                      setFieldValue(
                                        `additional_charges.${index}.amount`,
                                        e.target.value
                                      );

                                      setFieldValue(
                                        `additional_charges.${index}.total_amount`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {errors.additional_charges &&
                                  errors.additional_charges[index] &&
                                  errors.additional_charges[index].amount &&
                                  touched.additional_charges &&
                                  touched.additional_charges[index] &&
                                  touched.additional_charges[index].amount ? (
                                    <span className="text-xs text-red-500">
                                      {errors.additional_charges[index].amount}
                                    </span>
                                  ) : null}
                                </div>

                                <div>
                                  <Label text="Tax" isRequired={true} />
                                  <Field
                                    type="text"
                                    className={`${
                                      errors.additional_charges &&
                                      errors.additional_charges[index] &&
                                      errors.additional_charges[index].tax &&
                                      touched.additional_charges &&
                                      touched.additional_charges[index] &&
                                      touched.additional_charges[index].tax
                                        ? "!border-red-500"
                                        : null
                                    } border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                                    placeholder="Enter Amount"
                                    name={`additional_charges.${index}.tax`}
                                    autoComplete="off"
                                    onBlur={(e) => {
                                      setFieldValue(
                                        `additional_charges.${index}.tax`,
                                        e.target.value
                                      );

                                      let amount =
                                        (values.additional_charges[index]
                                          .amount *
                                          e.target.value) /
                                        100;

                                      setFieldValue(
                                        `additional_charges.${index}.total_amount`,
                                        parseInt(
                                          values.additional_charges[index]
                                            .amount
                                        ) + amount
                                      );
                                    }}
                                  />
                                  {errors.additional_charges &&
                                  errors.additional_charges[index] &&
                                  errors.additional_charges[index].tax &&
                                  touched.additional_charges &&
                                  touched.additional_charges[index] &&
                                  touched.additional_charges[index].tax ? (
                                    <span className="text-xs text-red-500">
                                      {errors.additional_charges[index].tax}
                                    </span>
                                  ) : null}
                                </div>

                                <div>
                                  <Label
                                    text="Total Amount"
                                    isRequired={true}
                                  />
                                  <TextInput
                                    name={`additional_charges.${index}.total_amount`}
                                    isError={
                                      errors.additional_charges &&
                                      errors.additional_charges[index] &&
                                      errors.additional_charges[index]
                                        .total_amount &&
                                      touched.additional_charges &&
                                      touched.additional_charges[index] &&
                                      touched.additional_charges[index]
                                        .total_amount
                                    }
                                  />
                                  {errors.additional_charges &&
                                  errors.additional_charges[index] &&
                                  errors.additional_charges[index]
                                    .total_amount &&
                                  touched.additional_charges &&
                                  touched.additional_charges[index] &&
                                  touched.additional_charges[index]
                                    .total_amount ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.additional_charges[index]
                                          .total_amount
                                      }
                                    </span>
                                  ) : null}
                                </div>
                                <div className="flex items-center space-x-4 mt-8">
                                  <BiPlusCircle
                                    onClick={() =>
                                      push({
                                        charge_name: "",
                                        amount: "",
                                        tax: "",
                                        total_amount: "",
                                      })
                                    }
                                    className="text-xl text-blue-500"
                                  />
                                  {values.additional_charges.length > 1 && (
                                    <BiMinusCircle
                                      className="text-xl text-red-500"
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    />
                    <div className="flex justify-end">
                      <div className="w-1/4">
                        <Button
                          text="Add Additional Charges"
                          isEnabled="true"
                          type="submit"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BillingContainer;
