import React from "react";
import Logo from "../../assets/simple-logo.svg";
import {
  BiArchive,
  BiCreditCard,
  BiHome,
  BiNote,
  BiPause,
} from "react-icons/bi";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="border w-32 px-1 flex flex-col items-center h-screen">
      <div className="mt-3 mb-11">
        <img src={Logo} className="w-full" alt="login banner" />
      </div>
      <div className="flex flex-col items-center gap-y-4">
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            isActive
              ? "bg-[#E6EDF9] px-4 py-2 rounded-lg"
              : "px-4 py-2 rounded-lg"
          }
        >
          <BiHome className="w-full h-8 mb-2 text-[#3C81FC]" />
          <p className="text-[#142243] text-base">Home</p>
        </NavLink>
        <NavLink
          to="/order"
          className={({ isActive }) =>
            isActive
              ? "bg-[#E6EDF9] px-4 py-2 rounded-lg"
              : "px-4 py-2 rounded-lg"
          }
        >
          <BiArchive className="w-full h-8 mb-2 text-[#3C81FC]" />
          <p className="text-[#142243] text-base">Order</p>
        </NavLink>
        <NavLink
          to="/hold"
          className={({ isActive }) =>
            isActive
              ? "bg-[#E6EDF9] px-4 py-2 rounded-lg"
              : "px-4 py-2 rounded-lg"
          }
        >
          <BiPause className="w-full h-10 mb-2 text-[#3C81FC]" />
          <p className="text-[#142243] text-base">Hold</p>
        </NavLink>
        <NavLink
          to="/payment/sales"
          className={({ isActive }) =>
            isActive
              ? "bg-[#E6EDF9] px-4 py-2 rounded-lg"
              : "px-4 py-2 rounded-lg"
          }
        >
          <BiCreditCard className="w-full h-8 mb-2 text-[#3C81FC]" />
          <p className="text-[#142243] text-base">Payment</p>
        </NavLink>
        <NavLink
          to="/credit-note"
          className={({ isActive }) =>
            isActive
              ? "bg-[#E6EDF9] px-4 py-2 rounded-lg"
              : "px-4 py-2 rounded-lg"
          }
        >
          <BiNote className="w-full h-8 mb-2 text-[#3C81FC]" />
          <p className="text-[#142243] text-base text-center">Credit Note</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
