import { Field } from "formik";
import React from "react";

const SelectInput = ({ selectOptions, isError, name, onblur }) => {
  return (
    <>
      <Field
        as="select"
        name={name}
        className={`${
          isError ? "!border-red-500" : null
        } custom-select border border-[#DBE4F2] mt-2 p-2 h-12 rounded bg-white text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
        onChange={onblur}
      >
        <option value="">Please select item</option>

        {selectOptions && selectOptions.length > 0 &&
          selectOptions.map((op, index) => (
            <>
              <option key={index} value={op.id}>
                {op.name || op.product_name}
              </option>
            </>
          ))}
      </Field>
    </>
  );
};

export default SelectInput;
