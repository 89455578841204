import React from "react";
import { useState } from "react";
import { useAsyncEffect } from "use-async-effect";
import * as api from "../../../http";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const CustomerDisplay = () => {
  const [displayData, setDisplayData] = useState(null);
  const [imageURL, setImageURL] = useState("");

  const [cartItems, setCartItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState(null);
  const [discountInitialValue, setDiscountInitialValue] = useState({
    discount_type: "",
    discount: "",
  });

  const [additionalCharges, setAdditionalCharges] = useState(0);
  const [additionalChargesInitialValues, setAdditionalChargesInitialValues] =
    useState({
      additional_charges: [
        {
          charge_name: "",
          amount: "",
          tax: "",
          total_amount: "",
        },
      ],
    });

  useAsyncEffect(async () => {
    try {
      const response = await api.customerDisplayData();

      if (response.status === 200 && response.data.data.length > 0) {
        setDisplayData(response.data.data[0]);
        setImageURL(response.data.url);
      }
    } catch (err) {
      console.error("Customer Display Err:>", err);
    }
  }, []);

  useEffect(() => {
    const checkData = () => {
      if (localStorage.getItem("cart-items")) {
        setCartItems(JSON.parse(localStorage.getItem("cart-items")));
      } else {
        setCartItems([])
      }
      
      if (localStorage.getItem("cart-discount")) {
        let d = JSON.parse(localStorage.getItem("cart-discount"));
        setDiscountInitialValue(d);
        setDiscountType(d.discount_type);
        setDiscount(d.discount);
      }

      if (localStorage.getItem("cart-additional-charges")) {
        let d = JSON.parse(localStorage.getItem("cart-additional-charges"));
        setAdditionalChargesInitialValues(d);
        let ac = 0;
        d.additional_charges.forEach((i) => {
          ac = ac + i.total_amount;
        });
        setAdditionalCharges(ac);
      }
    };

    window.addEventListener("storage", checkData);

    return () => {
      window.removeEventListener("storage", checkData);
    };
  }, []);

  const getSubTotal = () => {
    let totalAmt = 0;
    if (cartItems.length > 0) {
      cartItems.forEach((i) => {
        totalAmt = totalAmt + parseInt(i.cart_qnt) * i.mrp;
      });
    }

    return totalAmt;
  };

  const getTax = () => {
    let totalTax = 0;
    if (cartItems.length > 0) {
      cartItems.forEach((i) => {
        let amt = parseInt(i.cart_qnt) * i.mrp;
        if (i.add_vat === "yes") {
          totalTax = totalTax + (amt * 20) / 100;
        }
      });
    }

    return totalTax;
  };

  const getTotal = () => {
    let value = getSubTotal();

    if (discountType === "amount" && value > discount) {
      value = value - parseInt(discount);
    }

    if (discountType === "percentage") {
      let da = (value * parseInt(discount)) / 100;
      value = value - da;
    }
    value = value + additionalCharges + getTax();

    localStorage.setItem("cart-total", value);

    return value;
  };

  return (
    <div className="flex h-screen">
      <div className="w-2/6">
        <div className="flex flex-col justify-between">
          <div className="px-6 h-[29rem] overflow-y-auto mt-5">
            <p className="text-xl text-[#142243] mb-6">Bills</p>
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((ci, index) => (
                <div className="grid grid-cols-2 gap-4 mb-4" key={index}>
                  <div className="flex flex-row items-center space-x-2">
                    <img
                      src={ci.image}
                      alt={ci.product_name}
                      className="w-16 rounded-lg"
                    />
                    <div className="flex flex-col gap-y-1">
                      <h1 className="font-bold">{ci.product_name}</h1>
                      <p className="text-sm text-gray-600">MRP: {ci.mrp}</p>
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-2">
                    <div>
                      <p>{ci.cart_qnt}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="bg-[#DBE4F2] py-3 px-6">
            <div className="flex flex-row justify-between items-center mb-3 text-sm">
              <div>Subtotal</div>
              <div>£{getSubTotal()}</div>
            </div>
            <div className="flex flex-row justify-between items-center mb-3 text-sm">
              <div>Discount</div>
              <div>
                {discount} {discountType === "percentage" ? "%" : "£"}
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mb-3 text-sm">
              <div>Charges</div>
              <div>£{additionalCharges}</div>
            </div>
            <div className="flex flex-row justify-between items-center mb-4 text-sm">
              <div>Taxes (20%)</div>
              <div>£{getTax()}</div>
            </div>
            <hr className=" !border-black" />
            <div className="flex flex-row justify-between items-center mt-4 mb-4 text-sm">
              <div className="text-2xl">Total</div>
              <div className="text-2xl">£{getTotal()}</div>
            </div>
          </div>
        </div>
      </div>
      {displayData && (
        <div className="w-2/3 h-screen overflow-hidden p-10">
          <div className="flex flex-col">
            <div>
              <img
                src={`${imageURL}/${displayData.image}`}
                alt="display-img"
                className="w-1/2"
              />
            </div>
            <div className="mt-6">
              <p className="text-[#3C81FC] text-xl text-justify">
                {displayData.description}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDisplay;
