import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../http";

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.login(data);
      if (response.data.status === 200 || response.data.status === 201) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setLogout: (state) => {
      localStorage.clear();
      localStorage.removeItem("pos-access");
      localStorage.removeItem("pos-user");
      localStorage.removeItem("cart-items");
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      localStorage.setItem("pos-access", action.payload.data.access_token);
      localStorage.setItem(
        "pos-user",
        JSON.stringify({
          name: action.payload.data.employee.name,
          email: action.payload.data.employee.email,
          account_id: action.payload.data.employee.account_id,
          id: action.payload.data.employee.id,
        })
      );
      state.user = {
        name: action.payload.data.employee.name,
        email: action.payload.data.employee.email,
        account_id: action.payload.data.employee.account_id,
        id: action.payload.data.employee.id,
      };
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { setUser, setLogout } = authSlice.actions;
export default authSlice.reducer;
