import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { BiArrowBack, BiMinus, BiPlus, BiQrScan } from "react-icons/bi";
import { BillingContainer, Button, Label, Modal } from "../../../components";
import * as api from "../../../http";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { TiDelete, TiBackspace } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { emptyMyCart } from "../../../store/slices/cart";
import axios from "axios";

const Pay = () => {
  const [isCashPayModal, setCashPayModalOpen] = useState(false);
  const [isCashPayPreview, setCashPayPreview] = useState(false);

  const [cashPaymentInitialValues, setCashPaymentInitialValues] = useState({
    cart_amount: localStorage.getItem("cart-total"),
    customer_amount: "",
    change_amount: "",
  });

  const [changeAmount, setChangeAmount] = useState(0);

  const [customer, setCustomers] = useState([]);

  const getCustomerById = async (search) => {
    try {
      const response = await api.customerSearch({ value: search });
      if (response.status === 200 && response.data.data.length > 0) {
        let customer = response.data.data;
        setCustomers(customer);
      } else {
        toast.error("Wrong", {
          position: "top-right",
        });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const [changeAmountList, setChangeAmountList] = useState([
    { face: "50 Pound", count: 0, value: 5000 },
    { face: "20 Pound", count: 0, value: 2000 },
    { face: "10 Pound", count: 0, value: 1000 },
    { face: "5 Pound", count: 0, value: 500 },
    { face: "2 Pound", count: 0, value: 200 },
    { face: "1 Pound", count: 0, value: 100 },
    { face: "50 Pence", count: 0, value: 50 },
    { face: "20 Pence", count: 0, value: 20 },
    { face: "10 Pence", count: 0, value: 10 },
    { face: "5 Pence", count: 0, value: 5 },
    { face: "2 Pence", count: 0, value: 2 },
    { face: "1 Pence", count: 0, value: 1 },
  ]);

  const plusAmt = (index) => {
    let newAmt = [...changeAmountList];
    newAmt[index].count = newAmt[index].count + 1;
    setChangeAmountList(newAmt);
  };

  const minusAmt = (index) => {
    let newAmt = [...changeAmountList];
    if (newAmt[index].count !== 0) {
      newAmt[index].count = newAmt[index].count - 1;
      setChangeAmountList(newAmt);
    }
  };

  const keyboardInput = (v) => {
    let fields = { ...cashPaymentInitialValues };
    fields["customer_amount"] =
      "" + cashPaymentInitialValues.customer_amount + v;

    changeAmountCalc(fields.customer_amount, "keyboard");
    setCashPaymentInitialValues(fields);
  };

  const clearCustomerAmount = () => {
    let fields = { ...cashPaymentInitialValues };
    fields["customer_amount"] = "";
    changeAmountCalc("", "clear");
    setCashPaymentInitialValues(fields);
  };

  const backSpaceCustomerAmount = () => {
    let fields = { ...cashPaymentInitialValues };
    fields["customer_amount"] = fields.customer_amount.slice(0, -1);
    setCashPaymentInitialValues(fields);
  };

  const changeAmountCalc = (amt, type) => {
    let fields = { ...cashPaymentInitialValues };
    if (type === "clear") {
      fields["change_amount"] = 0;
      setChangeAmount(0);
    }

    if (type === "keyboard") {
      let fa = amt - fields.cart_amount;
      fields["change_amount"] = fa;

      if (fa <= 0) {
        setChangeAmount(0);
      } else {
        setChangeAmount(amt - fields.cart_amount);
      }
    }

    setCashPaymentInitialValues(fields);
  };

  const calculateChange = (change) => {
    let result = {};
    change = change * 100;

    const newChangeAmountList = [...changeAmountList];

    newChangeAmountList.forEach(({ face, value }, index) => {
      result[index] = (change - (change % value)) / value;
      change = change % value;
    });

    Object.keys(result).forEach((i) => {
      if (result[i] !== 0) {
        newChangeAmountList[i] = {
          ...newChangeAmountList[i],
          count: result[i],
        };
      }
    });

    setChangeAmountList(newChangeAmountList);
  };

  const [cartItems, setCartItems] = useState([]);
  const [discount, setDiscount] = useState({});
  const [additionalCharges, setAdditionalCharges] = useState([]);

  const finalCart = useCallback(() => {
    if (localStorage.getItem("cart-items")) {
      let cartI = JSON.parse(localStorage.getItem("cart-items"));
      setCartItems(cartI);
    }

    if (localStorage.getItem("cart-discount")) {
      let d = JSON.parse(localStorage.getItem("cart-discount"));
      setDiscount(d);
    }

    if (localStorage.getItem("cart-additional-charges")) {
      let d = JSON.parse(
        localStorage.getItem("cart-additional-charges")
      ).additional_charges;
      setAdditionalCharges(d);
    }
  }, []);

  useEffect(() => {
    finalCart();
  }, [finalCart]);

  const getSubTotal = (cartItems) => {
    let totalAmt = 0;
    cartItems.forEach((i) => {
      totalAmt = totalAmt + parseInt(i.cart_qnt) * i.mrp;
    });
    return totalAmt;
  };

  const getTax = (cartItems) => {
    let totalTax = 0;
    cartItems.forEach((i) => {
      let amt = parseInt(i.cart_qnt) * i.mrp;
      if (i.add_vat === "yes") {
        totalTax = totalTax + (amt * 20) / 100;
      }
    });

    return totalTax;
  };

  const getAdditionalChargeValue = (charges) => {
    let ac = 0;
    charges.forEach((i) => {
      ac = ac + i.total_amount;
    });

    return ac;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emptyCart = () => {
    dispatch(emptyMyCart());
    setCartItems([]);
    setAdditionalCharges(0);
    setDiscount(0);

    localStorage.removeItem("cart-items");
    localStorage.removeItem("cart-total");

    navigate("/dashboard");
  };

  const payByCard = async () => {
    console.log("pay by card -->");

    const api = axios.create({
      baseURL: `https://pc304b470000.connect.paymentsense.cloud/pac/terminals`,
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
      },
      auth: {
        username: "pc304b470000",
        password: "50a33f93-6fd6-4bef-9d40-4216f5767c14",
      },
    });

    try {
      const response = await api.post(`/06313660/transactions`, {
        transactionType: "SALE",
        amount: 1,
        amountCashback: 0,
        currency: "GBP",
        cardholderNotPresent: true,
      });

      console.log("Response --->", response)
      // if (response.status === 200 || response.status === 201) {
      //   toast.success(response.data.message, {
      //     position: "top-right",
      //   });
      //   // navigate("/contact/customer");
      // } else {
      //   toast.error(response.data.message, {
      //     position: "top-right",
      //   });
      // }
    } catch (err) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
      });
    }
  };

  return (
    <div className="flex flex-row">
      <div className="w-2/3 h-screen overflow-y-auto flex flex-col">
        <div className="flex flex-row justify-between items-center px-6 py-2 mb-5">
          <Link to="/dashboard" className="flex flex-row items-center gap-3">
            <BiArrowBack className="text-[#3C81FC] h-8 w-8" />
            <h1 className="font-bold text-xl">Pay </h1>
          </Link>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center">
              <input
                name="push-notifications"
                type="radio"
                className="focus:ring-[#3C81FC] h-4 w-4 text-[#3C81FC] border-gray-300"
              />
              <label className="ml-3 block text-md font-medium text-[#142243]">
                Walk in
              </label>
            </div>
            <div className="flex items-center">
              <input
                name="push-notifications"
                type="radio"
                className="focus:ring-[#3C81FC] h-4 w-4 text-[#3C81FC] border-gray-300"
              />
              <label className="ml-3 block text-md font-medium text-[#142243]">
                Delivery
              </label>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col">
            <div className="relative w-full shadow-xs px-6">
              <div className="flex absolute inset-y-0 left-0 items-center pl-8 pointer-events-none">
                <BiQrScan className="w-5 h-5 text-[#3C81FC]" />
              </div>
              <input
                type="text"
                id="simple-search"
                className="border border-[#EAEFF8] text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5 shadow-lg shadow-[#3C81FC]/10 focus:outline-none focus:border-[#142243] focus:ring-[#142243]"
                placeholder="Scan loyalty customer card or Enter Number"
                onBlur={async (e) => await getCustomerById(e.target.value)}
              />
            </div>
            {customer.length > 0 &&
              customer.map((c, index) => (
                <div className="m-6 p-2 w-1/2" key={index}>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center justify-between space-x-8">
                      <p className="text-[#525D68] text-base">Customer Name</p>
                      <p className="text-[#142243] text-base">{c.name}</p>
                    </div>
                    <div className="flex items-center justify-between space-x-8">
                      <p className="text-[#525D68] text-base">Mobile no.</p>
                      <p className="text-[#142243] text-base">Customer Name</p>
                    </div>
                    <div className="flex items-center justify-between space-x-8">
                      <p className="text-[#525D68] text-base">Last bill Amt</p>
                      <p className="text-[#142243] text-base">--</p>
                    </div>
                    <div className="flex items-center justify-between space-x-8">
                      <p className="text-[#525D68] text-base">
                        Available point
                      </p>
                      <p className="text-[#142243] text-base">
                        {c.available_points ? c.available_points : 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between space-x-8">
                      <p className="text-[#525D68] text-base">Available Amt</p>
                      <p className="text-[#142243] text-base">--</p>
                    </div>
                    <div className="flex items-center justify-between mt-12">
                      <p className="text-[#3C81FC] text-base border border-[#3C81FC] rounded-full px-16 py-2">
                        Redeem
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Pay buttons */}
          <div className=" space-y-4 p-4">
            <button
              className={`bg-[#1267FF] py-3 text-white rounded-full w-full`}
              onClick={() => setCashPayModalOpen(true)}
            >
              Pay by cash
            </button>

            <button
              className={`bg-[#1267FF] py-3 text-white rounded-full w-full`}
              onClick={() => payByCard()}
            >
              Pay by Card
            </button>
           
            <Button text="Pay by bank" isEnabled={true} />
            <button
              className={`py-3 text-[#3C81FC] border border-[#3C81FC] rounded-full w-full`}
            >
              Pay later
            </button>
          </div>
        </div>

        {isCashPayModal && (
          <Modal setIsOpen={setCashPayModalOpen}>
            <div className="w-[40rem] h-auto p-8">
              <h1 className="text-[#142243] font-bold text-xl">Cash Payment</h1>

              <div className="mt-6">
                <Formik
                  initialValues={cashPaymentInitialValues}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    calculateChange(changeAmount);
                    setCashPayModalOpen(false);
                    setCashPayPreview(true);

                    let newOrder = {
                      customer_id: "",
                      customer_name: "",
                      order_type: "",
                      subtotal: 0,
                      total_amount: 0,
                      discount_per: 0,
                      discount: 0,
                      used_point: 0,
                      loyalty_discount: 0,
                      charges: 0,
                      charges_item: [],
                      tax: 0,
                      due_amount: 0,
                      payment_method: "", // 'Cash', 'Card','Bank','Later','Cheque'
                      change_amount: "",
                      taken_amount: "",
                      order_item: [],
                      is_reedeem: "No", // Yes, No,
                      credit_use: 0,
                    };

                    newOrder["change_amount"] = changeAmount;
                    newOrder["charges"] =
                      getAdditionalChargeValue(additionalCharges);
                    newOrder["charges_item"] = additionalCharges;

                    newOrder["credit_use"] = 0;

                    if (discount.discount_type === "amount") {
                      newOrder["discount"] = discount.discount;
                      newOrder["discount_per"] = 0;
                    }

                    if (discount.discount_type === "percentage") {
                      newOrder["discount"] =
                        (getSubTotal(cartItems) * parseInt(discount.discount)) /
                        100;
                      newOrder["discount_per"] = discount.discount;
                    }

                    newOrder["due_amount"] = 0;
                    newOrder["is_reedeem"] = "No";
                    newOrder["loyalty_discount"] = 0;

                    let formattedCart = [];
                    cartItems.forEach((i) => {
                      formattedCart.push({
                        product_id: i.id,
                        product_name: i.product_name,
                        quantity: i.cart_qnt,
                        price: i.cart_qnt * parseInt(i.mrp),
                      });
                    });
                    newOrder["order_item"] = formattedCart;

                    newOrder["order_type"] = "Walk In";
                    newOrder["payment_method"] = "Cash";
                    newOrder["subtotal"] = getSubTotal(cartItems);
                    newOrder["taken_amount"] =
                      cashPaymentInitialValues.customer_amount;
                    newOrder["tax"] = getTax(cartItems);
                    newOrder["total_amount"] =
                      localStorage.getItem("cart-total");
                    newOrder["used_point"] = 0;
                    newOrder["customer_id"] = 0;
                    newOrder["customer_name"] = "Guest Customer";

                    try {
                      const response = await api.createOrder(newOrder);

                      if (response.status === 200 || response.status === 201) {
                        toast.success(response.data.message, {
                          position: "top-right",
                        });
                        // navigate("/contact/customer");
                      } else {
                        toast.error(response.data.message, {
                          position: "top-right",
                        });
                      }
                    } catch (err) {
                      toast.error("Something went wrong! Please try again.", {
                        position: "top-right",
                      });
                    }
                  }}
                >
                  {({ errors }) => (
                    <Form className="w-full">
                      <div className="flex justify-between items-center">
                        <div className="mb-6 w-[60%] relative">
                          <Label text="Amount" isRequired={true} />
                          <div>
                            <Field
                              type="text"
                              className={`border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                              placeholder="Amount given by customer"
                              name="cart_amount"
                              disabled={true}
                            />
                            <p className="absolute top-11 right-5">£</p>
                          </div>
                        </div>
                        <div className="mb-6 mt-8 w-[30%]"></div>
                      </div>

                      <div className="flex flex-row rounded-md space-x-4 shadow-lg shadow-[#3C81FC]/15 p-4">
                        <div className="flex flex-col w-1/4">
                          <div className="flex flex-col items-center">
                            <Field
                              type="text"
                              name="change_amount"
                              className="!bg-while text-[#142243] text-2xl block text-center w-full"
                              value={changeAmount}
                            />
                            <p className="text-[#151718] text-sm">Change</p>
                          </div>

                          <div className="grid grid-cols-2 gap-2 mt-6">
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £1
                            </div>
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £2
                            </div>
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £5
                            </div>
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £10
                            </div>
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £20
                            </div>
                            <div className="text-center rounded-md py-3 bg-[#E0E0E0]">
                              £50
                            </div>
                            <button
                              className="text-white col-span-2 rounded-full py-2 bg-[#1267FF] mt-2"
                              type="submit"
                            >
                              Pay Now
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-col w-3/4">
                          <div className="mb-3 relative">
                            <Field
                              type="text"
                              className="bg-white py-3 px-10 w-full border border-gray-200 rounded text-right"
                              name="customer_amount"
                              disabled={true}
                            />
                            <TiDelete
                              className="absolute text-2xl text-[#686C6F] top-3 left-3 cursor-pointer"
                              onClick={() => clearCustomerAmount()}
                            />
                            <TiBackspace
                              className="absolute text-2xl text-[#686C6F] top-3 right-3 cursor-pointer"
                              onClick={() => backSpaceCustomerAmount()}
                            />
                          </div>
                          <div className="grid grid-cols-3 gap-2 mt-[14px]">
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={() => keyboardInput(7)}
                            >
                              7
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(8)}
                            >
                              8
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(9)}
                            >
                              9
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(4)}
                            >
                              4
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(5)}
                            >
                              5
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(6)}
                            >
                              6
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(3)}
                            >
                              3
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(2)}
                            >
                              2
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(1)}
                            >
                              1
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(0)}
                            >
                              0
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput("00")}
                            >
                              00
                            </div>
                            <div
                              className="rounded-md py-3 bg-[#E0E0E0] text-center"
                              onClick={(e) => keyboardInput(".")}
                            >
                              .
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
        {isCashPayPreview && (
          <Modal setIsOpen={setCashPayPreview}>
            <div className="w-[40rem] h-auto p-8">
              <h1 className="text-[#142243] font-bold text-xl">
                Cash Payment Preview
              </h1>

              <div className="mt-6">
                <Formik
                  initialValues={cashPaymentInitialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    values.change_amount = changeAmount;
                    setCashPayPreview(false);
                    emptyCart();
                    // console.log(values);
                  }}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form className="w-full">
                      <div className="flex justify-between items-center">
                        <div className="mb-6 w-[60%] relative">
                          <Label text="Amount" isRequired={true} />
                          <div>
                            <Field
                              type="text"
                              className={`border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                              placeholder="Amount given by customer"
                              name="customer_amount"
                              disabled={true}
                            />
                            <p className="absolute top-11 right-5">£</p>
                          </div>
                        </div>
                        <div className="mb-6 mt-8 w-[30%]">
                          <Button
                            text="Pay Now"
                            isEnabled="true"
                            type="submit"
                          />
                        </div>
                      </div>

                      <div className="rounded-md shadow-lg shadow-[#3C81FC]/15 p-4">
                        <div className="mb-6 w-[40%] relative">
                          <label className={`text-sm mb-2`}>
                            Change Amount
                          </label>
                          <div>
                            <Field
                              type="text"
                              className={`border border-[#DBE4F2] mt-2 p-2 h-10 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-1/2 sm:text-sm focus:ring-1`}
                              name="change_amount"
                              autoComplete="off"
                              value={changeAmount}
                              disabled={true}
                            />
                            <p className="absolute top-10 left-[5.5rem]">£</p>
                          </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                          {changeAmountList.length > 0 &&
                            changeAmountList.map((item, index) => (
                              <div key={index}>
                                <div className="flex flex-row justify-between items-center gap-2">
                                  <div>
                                    <p
                                      className={`${
                                        item.count > 0
                                          ? "text-green-600 font-bold"
                                          : null
                                      } 'text-sm'`}
                                    >
                                      {item.face}
                                    </p>
                                  </div>
                                  <div className="flex items-center">
                                    <div className="p-1 border rounded-full">
                                      <BiPlus
                                        className="h-4 w-4 text-[#3C81FC] cursor-pointer"
                                        onClick={() => plusAmt(index)}
                                      />
                                    </div>
                                    <div className="mx-2">
                                      <p
                                        className={`${
                                          item.count > 0
                                            ? "text-green-600 font-bold"
                                            : null
                                        } 'text-sm'`}
                                      >
                                        {item.count}
                                      </p>
                                    </div>
                                    <div className="p-1 border rounded-full">
                                      <BiMinus
                                        className="h-4 w-4 text-[#3C81FC] cursor-pointer"
                                        onClick={() => minusAmt(index)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Modal>
        )}
      </div>
      <BillingContainer />
    </div>
  );
};

export default Pay;
