import React from "react";
import Modal from "../Modal/Modal";

const Alert = ({setIsOpen}) => {
  return (
    <>
      <Modal setIsOpen={setIsOpen}></Modal>
    </>
  );
};

export default Alert;
